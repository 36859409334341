import React from "react";
import GenerateStaking from "../components/home/GenerateStaking";
// import TableMarket from "../components/home/TableMarket";
import Starking from "../components/home/Starking";
import BecomeDefily from "../components/home/BecomeDefily";
import Features from "../components/home/Features";
import AreYouReady from "../components/home/AreYouReady";
import FrequentlyAsked from "../components/home/FrequentlyAsked";

const Home = () => {
  return (
    <div className="home bg-gradient-to-b from-[#0E0E33] to-[#39307B] ">
      <div className="px-6 lg:px-[60px]">
        <GenerateStaking />
        {/* <TableMarket /> */}
        <Starking />
        <BecomeDefily />
      </div>

      <Features />
      <AreYouReady />
      <FrequentlyAsked />
    </div>
  );
};

export default Home;
