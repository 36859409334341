import React from "react";
import ButtonJoinNow from "../generals/ButtonJoinNow";
import mobileImg from "../../assets/imgs/mobileFiendly.png";
import simpleImg from "../../assets/imgs/simpleFeatures.png";
import fullyImg from "../../assets/imgs/fullyFeatures.png";
import { useTranslation } from "react-i18next";

const Features = () => {
  const { t } = useTranslation();

  return (
    <div className="py-[40px] lg:py-[80px] px-6 lg:px-[60px] bg-gradient-to-b from-[#ffffff1a] to-[#ffffff00] text-white" id="#features">
      <h1 className="text-[24px] mb-3">{t("Features")}</h1>
      <p className="montserrat-regular text-[16px]">{t("We have built a platform for your convenience")}</p>

      <div className="mt-8 lg:mt-12 flex flex-col lg:flex-row items-center justify-center">
        <div className="flex justify-between overflow-hidden py-8 lg:py-[40px] px-6 lg:px-[40px] mb-[18px] lg:mb-0 rounded-2xl border border-solid border-[#ffffff1a] bg-gradient-to-b from-[#ffffff1a] to-[#ffffff00] relative lg:w-3/6 h-[400px]">
          <div className="flex flex-col justify-between w-[57%] lg:w-2/5">
            <div className="montserrat-regular">
              <h1 className="text-[24px] mb-4 font-bold">{t("Mobile friendly")}</h1>
              <p className="text-[14px]">{t("A responsive web interface, and native applications designed from the ground up")}.</p>
            </div>

            <ButtonJoinNow 
            linkRedirect="https://app.defily.ai/" 
            text={t("Join Now")} 
            classContainer="border-none" />
          </div>
          <img src={mobileImg} alt="" className="absolute bottom-0 -right-[70px] lg:right-0" />
        </div>

        <div className="montserrat-regular ml-0 lg:ml-8">
          <div className="flex justify-between items-center py-8 lg:py-[40px] px-6 lg:px-[40px] rounded-2xl border border-solid border-[#ffffff1a] bg-gradient-to-b from-[#ffffff1a] to-[#ffffff00] mb-[18px] lg:mb-8">
            <div className="w-3/5">
              <h1 className="text-[24px] mb-4 font-bold">{t("Simple onboarding")}</h1>
              <p className="text-[14px]">{t("Get started in seconds, with your crypto wallet or email")}.</p>
            </div>

            <img src={simpleImg} alt="" />
          </div>
          <div className="flex justify-between items-center py-8 lg:py-[40px] px-6 lg:px-[40px] rounded-2xl border border-solid border-[#ffffff1a] bg-gradient-to-b from-[#ffffff1a] to-[#ffffff00]">
            <div className="w-3/5">
              <h1 className="text-[24px] mb-4 font-bold">{t("Fully decentralized")}</h1>
              <p className="text-[14px]">{t("A native chain and governance protocol for Defily")}.</p>
            </div>
            <img src={fullyImg} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
