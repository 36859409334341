import React from "react";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col lg:flex-row mt-16 lg:mt-20 h-full lg:h-[120vh] 2xl:h-[90vh]">
      <div className="bg-[#F5F7FC] p-6 lg:p-14 w-full  lg:w-2/6 ">
        <div className="flex flex-col justify-between h-full">
          <div>
            <h1 className="text-[36px] lg:text-[44px] text-[#20DABB] pb-7 border-b border-solid border-[#DBDFE4] text-center lg:text-start px-9 lg:px-0">
              {t("Privacy Policy")}
            </h1>
            <div className="montserrat-regular text-[16px] text-[#1E0E39] pt-7">
              <div className="font-bold mb-7 border-b border-solid border-[#DBDFE4]">
                <p className="mb-3">{t("Privacy Policy for")} DeFily.ai </p>
                <p className="pb-7">{t("EFFECTIVE DATE")}: 04/08/2024</p>
              </div>

              <p>{t("AtDefily Privacy Policy")}</p>
            </div>
          </div>

          <div className="p-2 rounded-[10px] border border-solid border-[#AD98FF] mt-7 lg:mt-12">
            <p className="montserrat-regular text-[16px] text-[#1E0E39]">{t("By using you agree to this Privacy Policy")}</p>
          </div>
        </div>
      </div>
      <div className="pt-6 lg:pt-14 pb-[40px] lg:pb-14 px-6 lg:px-14 text-[#1E0E39] text-[16px] montserrat-regular w-full lg:w-4/6 lg:overflow-y-scroll ">
        <div className="py-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#1E0E39] text-white font-bold flex justify-center items-center mr-2">1</p>
            <span className="font-bold">{t("Information We Collect")}</span>
          </div>
          <p className="mt-4">
            <span className="font-bold">{t("Wallet Information")}:</span>{" "}
            {t("We access your wallet address and transaction data when you connect your crypto wallet to our platform")}
          </p>
          <p className="mt-4">
            <span className="font-bold">{t("Technical Data")}:</span> {t("We may collect data such as your IP address")}
          </p>
        </div>

        <div className="py-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#1E0E39] text-white font-bold flex justify-center items-center mr-2">2</p>
            <span className="font-bold">{t("How We Use Your Information")}</span>
          </div>
          <p className="mt-4">
            <span className="font-bold">{t("Service Delivery")}:</span> {t("To provide and enhance our decentralized services")}
          </p>
          <p className="mt-4">
            <span className="font-bold">{t("Communication")}:</span> {t("To send updates or notifications related to your account and services")}
          </p>
        </div>

        <div className="py-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#1E0E39] text-white font-bold flex justify-center items-center mr-2">3</p>
            <span className="font-bold">{t("Data Security Protection Measures")}</span>
          </div>
          <p className="mt-4">{t("We implement security measures to protect your data")}</p>
          <p className="mt-4">
            <span className="font-bold">{t("Decentralized Control")}:</span> {t("We do not control or store your wallet data directly")}
          </p>
        </div>

        <div className="py-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#1E0E39] text-white font-bold flex justify-center items-center mr-2">4</p>
            <span className="font-bold">{t("Sharing your Information")}</span>
          </div>
          <p className="mt-4">
            <span className="font-bold">{t("Third Parties")}:</span> {t("Our services may interact with external Web3 protocols")}
          </p>
        </div>

        <div className="py-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#1E0E39] text-white font-bold flex justify-center items-center mr-2">5</p>
            <span className="font-bold">{t("Your Rights")}</span>
          </div>
          <p className="mt-4">
            <span className="font-bold">{t("Manage Preferences")}:</span> {t("You can manage your cookie settings through your browser")}
          </p>
          <p className="mt-4">
            <span className="font-bold">{t("Data Control")}:</span> {t("You control your wallet and transaction data through your wallet provider")}
          </p>
        </div>

        <div className="py-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#1E0E39] text-white font-bold flex justify-center items-center mr-2">6</p>
            <span className="font-bold">{t("Updates to This Policy")}</span>
          </div>
          <p className="mt-4">{t("We may update this Privacy Policy periodically")}</p>
        </div>

        <div className="py-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#1E0E39] text-white font-bold flex justify-center items-center mr-2">7</p>
            <span className="font-bold">{t("Contact Us")}</span>
          </div>
          <p className="mt-4">
            {t("For questions about this Privacy Policy please contact us at")} <span className="font-bold">{t("Email:")}: info@defily.ai</span>
          </p>
          <p className="mt-4">{t("By using you agree to this Privacy Policy")}</p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
