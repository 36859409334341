import React from "react";
import { useTranslation } from "react-i18next";

const TermsCondicions = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col lg:flex-row mt-16 lg:mt-20 h-full lg:h-[120vh] 2xl:h-[90vh]">
      <div className="bg-[#F5F7FC] p-6 lg:p-14 w-full  lg:w-2/6 ">
        <div className="flex flex-col justify-between h-full">
          <div>
            <h1 className="text-[36px] lg:text-[44px] text-[#7A2FF4] pb-7 border-b border-solid border-[#DBDFE4] text-center lg:text-start">{t("Terms & Conditions")}</h1>
            <div className="montserrat-regular text-[16px] text-[#1E0E39] pt-7">
              <div className="font-bold mb-7 border-b border-solid border-[#DBDFE4]">
                <p className="mb-3">{t("Terms and Conditions for")} DeFily.ai </p>
                <p className="pb-7">{t("EFFECTIVE DATE")}: 04/08/2024</p>
              </div>

              <p>{t("welcomeToDefily Terms and Conditions")}</p>
            </div>
          </div>

          <div className="p-2 rounded-[10px] border border-solid border-[#AD98FF] mt-7 lg:mt-12">
            <p className="montserrat-regular text-[16px] text-[#1E0E39]">{t("by using Defily Terms and Conditions")}</p>
          </div>
        </div>
      </div>
      <div className="pt-6 lg:pt-14 pb-[40px] lg:pb-14 px-6 lg:px-14 text-[#1E0E39] text-[16px] montserrat-regular w-full lg:w-4/6 lg:overflow-y-scroll ">
        <div className="pb-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#1E0E39] text-white font-bold flex justify-center items-center mr-2">1</p>
            <span className="font-bold">{t("Acceptance of Terms")}</span>
          </div>
          <p className="mt-4">{t("By using our website and services")}</p>
        </div>

        <div className="py-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#1E0E39] text-white font-bold flex justify-center items-center mr-2">2</p>
            <span className="font-bold">{t("Eligibility")}</span>
          </div>
          <p className="mt-4">
            <span className="font-bold">{t("Age Requirement")}:</span> {t("You must be at least 18 years old to use our services")}
          </p>
          <p className="mt-4">
            <span className="font-bold">{t("Geographic Restrictions")}:</span> {t("Residents of the United States North Korea and other blacklisted")}
          </p>
        </div>

        <div className="py-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#1E0E39] text-white font-bold flex justify-center items-center mr-2">3</p>
            <span className="font-bold">{t("Account Activation and Membership")}</span>
          </div>
          <p className="mt-4">
            <span className="font-bold">{t("Account Activation Fee")}:</span> {t("To activate your DeFily account NFT")}
          </p>
          <p className="mt-4">
            <span className="font-bold">{t("Membership Plans")}:</span> {t("We offer both yearly membership plans")}
          </p>
        </div>

        <div className="py-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#1E0E39] text-white font-bold flex justify-center items-center mr-2">4</p>
            <span className="font-bold">{t("Crypto Staking and Risks")}</span>
          </div>
          <p className="mt-4">
            <span className="font-bold">{t("Staking")}:</span> {t("When you stake your cryptocurrency with DeFily")}
          </p>
          <p className="mt-4">
            <span className="font-bold">{t("Responsibility for Losses")}:</span>{" "}
            {t("You are solely responsible for any losses resulting from your staking activities")}
          </p>
        </div>

        <div className="py-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#1E0E39] text-white font-bold flex justify-center items-center mr-2">5</p>
            <span className="font-bold">{t("Account Security and Wallets")}</span>
          </div>
          <p className="mt-4">
            <span className="font-bold">{t("Wallet Connection")}:</span>{" "}
            {t("You are required to connect your own crypto wallet to your DeFily account")}
          </p>
          <p className="mt-4">
            <span className="font-bold">{t("Account Responsibility")}:</span>{" "}
            {t("You are solely responsible for maintaining the security of your account and wallet")}
          </p>
        </div>

        <div className="py-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#1E0E39] text-white font-bold flex justify-center items-center mr-2">6</p>
            <span className="font-bold">{t("Affiliate Program")}</span>
          </div>
          <p className="mt-4">
            <span className="font-bold">{t("Affiliate Program")}:</span> {t("Participation in our affiliate program is optional")}
          </p>
          <p className="mt-4">
            <span className="font-bold">{t("Cross-Recruiting Restriction")}:</span>{" "}
            {t("Cross-recruiting or the recruitment of individuals who are already affiliated with another member or affiliate within DeFily")}
          </p>
        </div>

        <div className="py-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#1E0E39] text-white font-bold flex justify-center items-center mr-2">7</p>
            <span className="font-bold">{t("No Guarantee of Results")}</span>
          </div>
          <p className="mt-4">
            <span className="font-bold">{t("No Guarantees")}:</span>{" "}
            {t("We do not guarantee any specific financial outcomes or results from using our services")}
          </p>
          <p className="mt-4">
            <span className="font-bold">{t("Performance Fees")}:</span>{" "}
            {t("Profits from trading are subject to performance fees as detailed in your membership plan")}
          </p>
        </div>

        <div className="py-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#1E0E39] text-white font-bold flex justify-center items-center mr-2">8</p>
            <span className="font-bold">{t("Service Updates and Changes")}</span>
          </div>
          <p className="mt-4">
            <span className="font-bold">{t("Service Updates")}:</span> {t("DeFily reserves the right to update")}
          </p>
        </div>

        <div className="py-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#1E0E39] text-white font-bold flex justify-center items-center mr-2">9</p>
            <span className="font-bold">{t("Disclaimer of Liability")}</span>
          </div>
          <p className="mt-4">
            <span className="font-bold">{t("Financial Losses")}:</span>{" "}
            {t("DeFily is not responsible for any financial losses or damages incurred from trading activities or reliance on our services")}
          </p>
          <p className="mt-4">
            <span className="font-bold">{t("DeFi Risks")}:</span> {t("Cryptocurrency and DeFi trading involve high risk and market volatility")}
          </p>
        </div>

        <div className="py-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#1E0E39] text-white font-bold flex justify-center items-center mr-2">10</p>
            <span className="font-bold">{t("Taxes and Jurisdiction")}</span>
          </div>
          <p className="mt-4">{t("You are solely responsible for any taxes applicable to your staking rewards")}</p>
        </div>

        <div className="py-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#1E0E39] text-white font-bold flex justify-center items-center mr-2">11</p>
            <span className="font-bold">{t("Disclaimer of Liability")}</span>
          </div>
          <p className="mt-4">
            {t("For any questions or concerns regarding these Terms and Conditions")} <span className="font-bold">{t("Email:")} info@defily.ai</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsCondicions;
